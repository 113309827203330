.heroArea {
  display: flex;
}

.leftAreaRoot {
  flex: 1;
  border: 1px solid gray;
  height: 100px;
  text-align: center;
}

.rightAreaRoot {
  flex: 1;
  border: 1px solid gray;
  text-align: center;
}
